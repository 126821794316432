import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

interface InitialSettingsState {
  sidebarShow: string;
  asideShow: boolean;
  darkMode: boolean;
}

const initialSettingsState: InitialSettingsState = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: false,
};

export const actionTypes = {
  set: "[Set] Action",
};

export const reducer = persistReducer(
  {
    storage,
    key: "tanfolyamguru::settings",
    whitelist: ["sidebarShow", "asideShow", "darkMode"],
  },
  (
    state: InitialSettingsState = initialSettingsState,
    { type, ...rest }: { type: string },
  ) => {
    switch (type) {
      case actionTypes.set:
        return {
          ...state,
          ...rest,
        };
      default:
        return state;
    }
  },
);
