import "./scss/style.scss";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  BrowserRouter,
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { ToastContextProvider } from "./contexts/toast/ToastContext";
import EmailConfirmation from "./views/pages/auth/EmailConfirmation";
import PasswordReset from "./views/pages/auth/PasswordReset";
import PasswordResetInitiator from "./views/pages/auth/PasswordResetInitiator";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const SignIn = React.lazy(() => import("./views/pages/auth/SignIn"));
const Register = React.lazy(() => import("./views/pages/auth/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));

const stripe = process.env.REACT_APP_STRIPE_PUBLIC_KEY
  ? loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
  : null;

const App: React.FC = (): JSX.Element => {
  const { isAuthorized } = useSelector(
    ({ auth }: { auth: any }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual,
  );

  return (
    <ToastContextProvider>
      <Elements stripe={stripe}>
        <React.Suspense fallback={loading}>
          <BrowserRouter>
            <React.Fragment>
              {isAuthorized && (
                <Routes>
                  <Route path="/404" element={<Page404 />} />
                  <Route path="*" element={<TheLayout />} />
                  <Route
                    path="/sign-in"
                    element={<Navigate replace to="/dashboard" />}
                  />
                </Routes>
              )}
              {!isAuthorized && (
                <Routes>
                  <Route path="/sign-in" element={<SignIn />} />
                  <Route path="/sign-up" element={<Register />} />
                  <Route
                    path="/forgot-password"
                    element={<PasswordResetInitiator />}
                  />
                  <Route path="/reset-password" element={<PasswordReset />} />
                  <Route
                    path="/confirm-email"
                    element={<EmailConfirmation />}
                  />
                  <Route
                    path="*"
                    element={<Navigate replace to="/sign-in" />}
                  />
                </Routes>
              )}
            </React.Fragment>
          </BrowserRouter>
        </React.Suspense>
      </Elements>
    </ToastContextProvider>
  );
};

export default App;
