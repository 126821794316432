import {
  cibBehance,
  cibCcAmex,
  cibCcMastercard,
  cibCcVisa,
  cibDribbble,
  cibFacebook,
  cibFlickr,
  cibGithub,
  cibGooglePay,
  cibInstagram,
  cibLinkedin,
  cibPaypal,
  cibPinterest,
  cibReddit,
  cibSkype,
  cibStackoverflow,
  cibStripe,
  cibTumblr,
  cibTwitter,
  cibVimeo,
  cibVk,
  cibXing,
  cibYahoo,
  cibYoutube,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cilAccountLogout,
  cilActionRedo,
  cilActionUndo,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowThickLeft,
  cilArrowTop,
  cilAsterisk,
  cilBan,
  cilBank,
  cilBasket,
  cilBeaker,
  cilBell,
  cilBold,
  cilBook,
  cilBookmark,
  cilBriefcase,
  cilBuilding,
  cilCalculator,
  cilCalendar,
  cilChartPie,
  cilChatBubble,
  cilCheck,
  cilCheckCircle,
  cilChevronBottom,
  cilChevronDoubleLeft,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilClipboard,
  cilCloudDownload,
  cilCode,
  cilCommentSquare,
  cilCopy,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDescription,
  cilDollar,
  cilDrop,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilExternalLink,
  cilEyedropper,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGift,
  cilGlobeAlt,
  cilGraph,
  cilGrid,
  cilHome,
  cilImage,
  cilImagePlus,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInfo,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLibrary,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoney,
  cilMoodGood,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPin,
  cilPlus,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilSchool,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSitemap,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilSync,
  cilTag,
  cilTags,
  cilTask,
  cilThumbUp,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilVoiceOverRecord,
  cilWarning,
  cilX,
  cilXCircle,
} from "@coreui/icons";

import { sygnet } from "./sygnet";

// eslint-disable-next-line import/prefer-default-export
export const icons = {
  sygnet,
  cilPin,
  cilArrowThickLeft,
  cilAlignCenter,
  cilAccountLogout,
  cilActionUndo,
  cilActionRedo,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilAsterisk,
  cilBan,
  cilBank,
  cilBuilding,
  cilBook,
  cilBasket,
  cilBell,
  cilBold,
  cilBriefcase,
  cilCopy,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilChatBubble,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilClipboard,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDescription,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilEyedropper,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGlobeAlt,
  cilGift,
  cilGraph,
  cilGrid,
  cilHome,
  cilInbox,
  cilInfo,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLibrary,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilMoney,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilSync,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilThumbUp,
  cilTrash,
  cilUnderline,
  cilUser,
  cilTag,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilVoiceOverRecord,
  cilX,
  cilXCircle,
  cilWarning,
  cilSitemap,
  cilSchool,
  cilChevronDoubleLeft,
  cilMoodGood,
  cilPlus,
  cilImage,
  cilImagePlus,
  cilExternalLink,
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl,
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cilBeaker,
};
