import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CSpinner,
} from "@coreui/react";
import qs from "qs";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { EMAIL_CONFIRMATION, fetcher } from "../../../utils";

const EmailConfirmation = () => {
  const navigate = useNavigate();
  // const { token } = useParams<{
  //   token: string;
  // }>();

  // const token = null;

  const { search } = useLocation();

  const { token } = qs.parse(search.slice(search.lastIndexOf("?") + 1));

  const [isLoading, setIsLoading] = React.useState(true);
  const [result, setResult] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  const confirmEmail = async (): Promise<void> => {
    try {
      const res = await fetcher(EMAIL_CONFIRMATION, "GET", {
        params: {
          token,
        },
      });
      setResult(res.data.message);
      setTimeout(() => {
        navigate("/sign-in");
      }, 3000);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    const init = async () => {
      await confirmEmail();
    };
    if (token) init();
  }, [token]);

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="9" lg="7" xl="6">
            <CCard className="mx-4">
              <CCardBody className="p-4">
                {result && (
                  <CRow>
                    <CCol md="12">
                      <CAlert color="success">{JSON.stringify(result)}</CAlert>
                    </CCol>
                  </CRow>
                )}
                {error && (
                  <CRow>
                    <CCol md="12">
                      <CAlert color="danger">{JSON.stringify(error)}</CAlert>
                    </CCol>
                  </CRow>
                )}
                <CForm>
                  <CInputGroup className="mb-3">
                    <CInputGroupPrepend>
                      <CInputGroupText>
                        <CIcon name="cil-tag" />
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      value={token?.toString() || ""}
                      disabled
                      type="text"
                      placeholder="E-mail megerősítő kód"
                      // valid={formik.touched.email && !formik.errors.email}
                      // invalid={formik.touched.email && !!formik.errors.email}
                    />
                  </CInputGroup>
                  <CButton
                    type="button"
                    color="primary"
                    disabled={isLoading}
                    block
                    onClick={confirmEmail}
                  >
                    {isLoading ? (
                      <>
                        <CSpinner color="white" size="sm" /> Megerősítés
                        folyamatban
                      </>
                    ) : (
                      "Megerősítés"
                    )}
                  </CButton>
                  {/* <h2
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "#2eb85c",
                    }}
                  >
                    {loading
                      ? "Email hitelesítés folyamatban..."
                      : "Email sikeresen hitelesítve! Átirányítás folyamatban..."}
                  </h2> */}
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default EmailConfirmation;
