import { CToast, CToastBody, CToaster, CToastHeader } from "@coreui/react";
import React, { createContext, ReactNode, useState } from "react";

import { Toast } from "../../types";

const ToastContext = createContext({
  add: (title: string, description: string, autohide?: number) => {},
});

export default ToastContext;

export function ToastContextProvider({ children }: { children: ReactNode }) {
  const [toasts, setToasts] = useState<Toast[]>([]);
  let index = 0;

  const add = (title: string, description: string, autohide?: number) => {
    const toast = { title, description, autohide };
    setToasts([...toasts, { id: index++, ...toast }]);
  };

  return (
    <ToastContext.Provider value={{ add }}>
      {children}
      <CToaster position="top-right" key="main-toaster">
        {toasts.map((toast) => (
          <CToast
            key={`toast-${toast.id}`}
            show
            autohide={toast.autohide ?? 4000}
            fade
          >
            <CToastHeader closeButton>{toast.title}</CToastHeader>
            <CToastBody>{toast.description}</CToastBody>
          </CToast>
        ))}
      </CToaster>
    </ToastContext.Provider>
  );
}
