const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
const frontendBaseUrl = process.env.REACT_APP_FRONTEND_BASE_URL;

export const BASE_URL = backendBaseUrl;
export const FRONT_BASE_URL = frontendBaseUrl;

// AUTH
export const LOGIN_URL = "auth/sign-in";
export const EMAIL_CONFIRMATION = "auth/confirm-email";
export const SCHOOL_SIGN_UP = "auth/sign-up";
export const PASSWORD_RESET = "auth/forgotten-password";
export const CHANGE_PASSWORD = "auth/reset-password";

export const ADMIN_SIGN_UP_URL = "auth/admin/sign-up";

export const GET_ALL_PACKAGES = "mod/packages?offset=0&limit=1000";
export const GET_ALL_PACKAGES_SCHOOL = "school/packages?offset=0&limit=1000";
export const ADD_PACKAGE = "mod/packages/add";
export const DELETE_PACKAGE = "mod/packages/delete";
export const UPDATE_PACKAGE = "mod/packages/update";

export const GET_ALL_SCHOOLS = "mod/schools?offset=0&limit=1000";
export const GET_BENEFITS_OF_CHOSEN_SCHOOL = "mod/benefits?offset=0&limit=1000";
export const GET_BENEFITS_OF_SCHOOL = "school/benefits?offset=0&limit=1000";
export const UPDATE_BENEFIT_ADMIN = "mod/benefits/update";
export const ADD_BENEFIT_ADMIN = "mod/benefits/add";
export const UPDATE_BENEFIT_SCHOOL = "school/benefits/update";
export const ADD_BENEFIT_SCHOOL = "school/benefits/add";
export const DELETE_BENEFIT_ADMIN = "mod/benefits/delete";
export const DELETE_BENEFIT_SCHOOL = "school/benefits/delete";

export const GET_ALL_CATEGORIES = "common/categories";
export const GET_CATEGORY_BY_ID = (id: string) => `common/categories/${id}`;
export const ADD_CATEGORY = "mod/categories/add";
export const UPDATE_CATEGORY = "mod/categories/update";
export const DELETE_CATEGORY = "mod/categories/delete";

export const GET_ALL_TAGS = "common/tags";
export const ADD_TAG = "mod/tags/add";
export const UPDATE_TAG = "mod/tags/update";
export const DELETE_TAG = "mod/tags/delete";

export const GET_COURSES_SCHOOL = "mod/courses";
export const ADMIN_ADD_COURSE_FOR_SCHOOL = "mod/courses/add";
export const ADMIN_UPDATE_COURSE_FOR_SCHOOL = "mod/courses/update";
export const ADMIN_DELETE_COURSE_FOR_SCHOOL = "mod/courses/delete";
export const ADMIN_CHANGE_COURSE_STATUS_FOR_SCHOOL = "mod/courses/changeStatus";
export const ADMIN_GET_CLASS_FOR_COURSE = "mod/courseCycles";
export const ADMIN_ADD_CLASS_FOR_SCHOOL = "mod/courseCycles/add";
export const ADMIN_UPDATE_CLASS_FOR_SCHOOL = "mod/courseCycles/update";
export const ADMIN_DUPLICATE_CLASS_FOR_SCHOOL = "mod/courseCycles/duplicate";
export const ADMIN_DELETE_CLASS_FOR_SCHOOL = "mod/courseCycles/delete";
export const GET_COURSE_DETAILS = "common/courses/details";
export const COPY_COURSE_ADMIN = "mod/courses/duplicate";
export const COPY_COURSE_SCHOOL = "school/courses/duplicate";

export const SCHOOL_GET_ALL_COURSES = "school/courses?offset=0&limit=1000";
export const SCHOOL_ADD_COURSE = "school/courses/add";
export const SCHOOL_EDIT_COURSE = "school/courses/update";
export const SCHOOL_DELETE_COURSE = "school/courses/delete";
export const SCHOOL_CHANGE_COURSE_STATUS = "school/courses/changeStatus";
export const SCHOOL_GET_CLASS_FOR_COURSE = "school/courseCycles";
export const SCHOOL_ADD_CLASS = "school/courseCycles/add";
export const SCHOOL_DUPLICATE_CLASS = "school/courseCycles/duplicate";
export const SCHOOL_EDIT_CLASS = "school/courseCycles/update";
export const SCHOOL_DELETE_CLASS = "school/courseCycles/delete";

export const ADMIN_EDIT_SCHOOL_DATA = "mod/schools/update";

export const ADMIN_ACCEPT_SCHOOL = (id: string) => `mod/schools/${id}/accept`;
export const ADMIN_REJECT_SCHOOL = (id: string) => `mod/schools/${id}/reject`;
export const ADMIN_VERIFY_SCHOOL = (id: string) => `mod/schools/${id}/verify`;

export const SCHOOL_GET_PERSONAL_DATA = "school/personalData";
export const SCHOOL_EDIT_PERSONAL_DATA = "school/personalData/update";
export const SCHOOL_DEFAULT_CALLBACK_PARAMETRS =
  "school/personalData/callbackparameters";

export const ATTACH_PACKAGE_TO_SCHOOL = "mod/packages/assign";
export const DELETE_PACKAGE_FROM_SCHOOL = "mod/packages/unassign?";
export const GIVE_PACKAGE_TO_SCHOOL = "mod/givePackage";
export const GET_SCHOOLS_ATTACHED_TO_PACKAGE =
  "mod/packages/schools?packageId=";

export const STRIPE_END_POINT = "school/payments/charge";

export const FILTER_END_POINT = "common/courses";
export const GET_FILTER_CATEGORIES = "common/categories";
export const GET_CATEGORIES_COURSES = "common/categories/courseNames";

export const ADMIN_GET_SCHOOL_LOGS = "mod/transactions?offset=0&limit=1000";
export const SCHOOL_GET_SCHOOL_LOGS = "school/transactions?offset=0&limit=1000";

export const ADMIN_ADD_IMAGE_FOR_LOCATION = "mod/locations/addImage";
export const DELETE_IMAGE_FOR_LOCATION = "mod/locations/deleteImage";

export const ADMIN_ADD_IMAGE_FOR_SCHOOL = "mod/schools/addImage";
export const ADMIN_DELETE_IMAGE_FOR_SCHOOL = "mod/schools/deleteImage";
export const ADD_IMAGE_FOR_SCHOOL = "school/schools/addImage";
export const DELETE_IMAGE_FOR_SCHOOL = "school/schools/deleteImage";

export const ADMIN_ADD_IMAGE_FOR_COURSE = "mod/courses/addImage";
export const ADMIN_DELETE_IMAGE_FOR_COURSE = "mod/courses/deleteImage";

export const SCHOOL_ADD_IMAGE_FOR_COURSE = "school/courses/addImage";
export const SCHOOL_DELETE_IMAGE_FOR_COURSE = "school/courses/deleteImage";

export const ADMIN_ADD_IMAGE_FOR_CATEGORY = "mod/categories/addImages";
export const ADMIN_DELETE_IMAGE_FOR_CATEGORY = "mod/categories/deleteImage";
export const ADMIN_SELECT_MAIN_IMAGE_FOR_CATEGORY =
  "mod/categories/selectMainImage?";

export const GET_ALL_LOCATIONS = "common/locations?offset=0&limit=1000";

export const IMAGE_PREVIEW_BASE_LINK = "";

export const ADMIN_EXCEL_EXPORT_APPLICANTS = "mod/applicants/export";
export const SCHOOL_EXCEL_EXPORT_APPLICANTS = "school/applicants/export";
export const ADMIN_CSV_EXPORT_COURSES = "mod/courses/export";
export const SCHOOL_CSV_EXPORT_COURSES = "school/courses/export";
export const ADMIN_RESEND_FAILED_INTEGRATIONS =
  "mod/applicants/integrations/failed/resend";

export const ADMIN_GET_APPLICANTS = "mod/applicants";
export const ADMIN_GET_SCHOOL = "mod/schools";
export const ADMIN_GET_SCHOOL_DEFAULT_CALLBACK_PARAMETRS = (id: string) =>
  `mod/schools/${id}/callbackparameters`;
export const ADMIN_GET_COURSE = "mod/courses";
export const SCHOOL_GET_COURSE = "school/courses";
export const SCHOOL_GET_APPLICANTS = "school/applicants";
export const SCHOOL_ADD_TAG = "school/tags/add";
export const ADMIN_DELETE_APPLICANT = "/mod/applicants/delete";
export const SCHOOL_DELETE_APPLICANT = "/school/applicants/delete";
export const SCHOOL_ADD_APPLICANT = "school/applicants/add";
export const ADMIN_ADD_APPLICANT = "mod/applicants/add";

export const ADMIN_GET_DASHBOARD_COUNTERS = "mod/statistics/counters";
export const SCHOOL_GET_DASHBOARD_COUNTERS = "school/statistics/counters";

export const GET_USER_SITE_COMMENTS = "common/userComments/site";
export const DELETE_USER_SITE_COMMENT = "mod/userComments/delete";
export const ADD_USER_SITE_COMMENT = "mod/userComments/add";
export const EDIT_USER_SITE_COMMENT = "mod/userComments/update";

export const ADMIN_GET_USER_COURSE_COMMENTS = "mod/userComments";
export const ADMIN_DELETE_USER_COURSE_COMMENT = "mod/userComments/delete";
export const ADMIN_ADD_USER_COURSE_COMMENT = "mod/userComments/add";
export const ADMIN_EDIT_USER_COURSE_COMMENT = "mod/userComments/update";
export const SCHOOL_GET_USER_COURSE_COMMENTS = "school/userComments";
export const SCHOOL_DELETE_USER_COURSE_COMMENT = "school/userComments/delete";
export const SCHOOL_ADD_USER_COURSE_COMMENT = "school/userComments/add";
export const SCHOOL_EDIT_USER_COURSE_COMMENT = "school/userComments/update";

export const SETTINGS_ENDPOINT = "settings";
export const STATISTICS_ENDPOINT = "statistics";

export const ATTACH_MODERATOR_TO_SCHOOL = "mod/users/moderators/assign";
export const DELETE_MODERATOR_FROM_SCHOOL = "mod/users/moderators/unassign";

export const GET_ALL_USERS = "mod/users";
export const CREATE_USER = "auth/admin/sign-up";
export const UPDATE_USER = "mod/users/update";
export const GET_MODERATORS_OF_SCHOOL = "mod/users/moderators";

export const EXPORT_CREDIT_HISTORY_FOR_MODERATOR = "mod/credithistory";
export const EXPORT_CREDIT_HISTORY_FOR_USER = "school/credithistory";
