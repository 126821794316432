import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CInvalidFeedback,
  CLabel,
  CRow,
  CSpinner,
} from "@coreui/react";
import { useFormik } from "formik";
import qs from "qs";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { fetcher } from "../../../utils";
import { CHANGE_PASSWORD } from "../../../utils/const";

const PasswordReset: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isPasswordReset, setIsPasswordReset] = React.useState(false);

  const { search } = useLocation();
  const { token } = qs.parse(search.slice(search.lastIndexOf("?") + 1));

  const validationSchema = Yup.object().shape({
    token: Yup.string().required("Kötelező"),
    password: Yup.string()
      .required("Kötelező")
      .min(6, "Legalább 6 karakter hosszúnak kell lennie")
      .max(120, "Legfeljebb 120 karakter lehet"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "A jelszavak nem egyeznek meg")
      .required("Kötelező"),
  });

  const initialValues = {
    token,
    password: "",
    confirmPassword: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setIsLoading(true);
      try {
        const result = await fetcher(CHANGE_PASSWORD, "POST", {
          data: {
            token: values.token,
            password: values.password,
          },
        });
        setIsPasswordReset(true);
        setTimeout(() => {
          navigate("/sign-in");
        }, 3000);
      } catch (e: any) {
        let errorMessage = "";
        switch (e.response.data) {
          case "ERROR.AUTH.PASSWORD_RESET.VERIFICATION_TOKEN_NOT_VALID":
            errorMessage =
              "Hiba található a biztonsági kódban! Kövesd az e-mailben küldött utasításokat.";
            break;
          case "ERROR.AUTH.PASSWORD_RESET.VERIFICATION_TOKEN_EXPIRED":
            errorMessage =
              "Hoppá! Úgy tűnik ez a biztonsági kód lejárt. Kérlek küldj új kérést jelszó cserére.";
            break;
        }
        setStatus({
          type: "error",
          message: errorMessage,
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8" lg="4">
            <CCardGroup>
              <CCard>
                <CCardBody>
                  <CForm onSubmit={formik.handleSubmit}>
                    <h1 className="pb-4 text-center">Jelszó újraállítás</h1>
                    {formik.status && (
                      <CRow>
                        <CCol md="12">
                          <CAlert color="danger">
                            {formik.status.message}
                          </CAlert>
                        </CCol>
                      </CRow>
                    )}
                    <CCol xs="12">
                      <CFormGroup>
                        <CLabel>Token</CLabel>
                        <CInput
                          type="text"
                          disabled={!!token}
                          {...formik.getFieldProps("token")}
                        />
                      </CFormGroup>
                    </CCol>
                    <CCol xs="12">
                      <CFormGroup>
                        <CLabel>Új jelszó</CLabel>
                        <CInput
                          type="password"
                          valid={
                            formik.touched.password && !formik.errors.password
                          }
                          invalid={
                            formik.touched.password && !!formik.errors.password
                          }
                          {...formik.getFieldProps("password")}
                        />
                        <CInvalidFeedback>
                          {formik.errors.password}
                        </CInvalidFeedback>
                      </CFormGroup>
                    </CCol>
                    <CCol xs="12">
                      <CFormGroup>
                        <CLabel>Új jelszó megerősítése</CLabel>
                        <CInput
                          type="password"
                          valid={
                            formik.touched.confirmPassword &&
                            !formik.errors.confirmPassword
                          }
                          invalid={
                            formik.touched.confirmPassword &&
                            !!formik.errors.confirmPassword
                          }
                          {...formik.getFieldProps("confirmPassword")}
                        />
                        <CInvalidFeedback>
                          {formik.errors.confirmPassword}
                        </CInvalidFeedback>
                      </CFormGroup>
                    </CCol>
                    <CRow>
                      <CCol xs="12">
                        {!isPasswordReset ? (
                          <CButton type="submit" color="primary" block>
                            {formik.isSubmitting || isLoading ? (
                              <CSpinner color="white" size="sm" />
                            ) : (
                              "Küldés"
                            )}
                          </CButton>
                        ) : (
                          <CAlert color="success" className="mt-3">
                            Jelszó sikeresen cserélve, átirányítás folyamatban.
                          </CAlert>
                        )}
                      </CCol>
                      <CCol xs="12" className="text-right">
                        <Link to="/sing-in">
                          <CButton color="link">Bejelentkezés oldal</CButton>
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default PasswordReset;
