import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

interface ISignIn {
  authToken: string;
  user: any;
}

interface InitialAuthState {
  user?: any;
  authToken?: string;
}

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
};

const initialAuthState: InitialAuthState = {
  user: null,
  authToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: "tanfolyamguru::auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action: any) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, user } = action.payload;

        return { authToken, user };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      default:
        return state;
    }
  },
);

export const actions = {
  login: async (details: ISignIn) => {
    await localStorage.setItem("authToken", details.authToken);

    return {
      type: actionTypes.Login,
      payload: details,
    };
  },
  logout: async () => {
    await localStorage.removeItem("authToken");

    return {
      type: actionTypes.Logout,
    };
  },
};
