import axios from "axios";

import { BASE_URL } from "./const";

type HTTPMethods = "GET" | "POST" | "PUT" | "DELETE";

export const getToken = () => {
  const auth = localStorage.getItem("persist:tanfolyamguru::auth");

  if (auth) {
    const token = JSON.parse(auth).authToken;
    if (token) {
      const trimmedToken = token.substr(1).slice(0, -1);
      return trimmedToken;
    }
  }

  return null;
};

export const instance = axios.create({
  baseURL: `${BASE_URL}/api`,
});

instance.interceptors.request.use(
  (config) => {
    const cfg = { ...config };

    cfg.headers.Authorization = `Bearer ${getToken()}`;

    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    let shouldRetry = false;
    const { config } = err;

    config.retryCount = config.retryCount || 0;

    if (
      (err.message === "Network Error" || err.response?.status === 401) &&
      config.retryCount === 1
    ) {
      window.location.href = "/sign-in";
      localStorage.clear();
      return Promise.reject(err);
    }

    if (err.message === "Network Error" || err.response.status === 401) {
      shouldRetry = true;
    }

    config.retryCount += 1;

    const backoff = new Promise((resolve) => {
      setTimeout(() => {
        resolve("");
      }, 3000);
    });

    return backoff.then(() => {
      if (shouldRetry) {
        return instance(config);
      }
      return Promise.reject(err);
    });
  },
);

export const fetcher = async (
  url: string,
  method: HTTPMethods,
  params?: any,
) => {
  const { data } = await instance({
    url,
    method,
    ...params,
  });

  return {
    data,
  };
};
