import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInvalidFeedback,
  CRow,
  CSpinner,
} from "@coreui/react";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { fetcher } from "../../../utils";
import { PASSWORD_RESET } from "../../../utils/const";

const PasswordResetInitiator: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isRequestSent, setIsRequestSent] = React.useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Hibás e-mail cím").required("Kötelező"),
  });

  const initialValues = {
    email: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setIsLoading(true);
      try {
        const result = await fetcher(PASSWORD_RESET, "GET", {
          params: {
            email: values.email,
          },
        });
        setIsRequestSent(true);
        setTimeout(() => {
          navigate("/sign-in");
        }, 3000);
      } catch (e: any) {
        let errorMessage = "";
        switch (e.response.data) {
          case "ERROR.AUTH.FORGOTTEN_PASSWORD.NO_USER_EXISTS_WITH_EMAIL":
            errorMessage = "E-mail cím nem található!";
            break;
          case "ERROR.AUTH.FORGOTTEN_PASSWORD.EMAIL_IS_NOT_VERIFIED":
            errorMessage = "Az e-mail cím még nem volt hitelesítve!";
            break;
        }
        setStatus({
          type: "error",
          message: errorMessage,
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8" lg="4">
            <CCardGroup>
              <CCard>
                <CCardBody>
                  <CForm onSubmit={formik.handleSubmit}>
                    <h1 className="pb-4 text-center">Elfelejtett jelszó</h1>
                    <p className="text-muted">
                      E-mailt küldünk a jelszavad újraállítására
                    </p>
                    {formik.status && (
                      <CRow>
                        <CCol md="12">
                          <CAlert color="danger">
                            {formik.status.message}
                          </CAlert>
                        </CCol>
                      </CRow>
                    )}
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="email"
                        placeholder="E-mail cím"
                        autoComplete="email"
                        valid={formik.touched.email && !formik.errors.email}
                        invalid={formik.touched.email && !!formik.errors.email}
                        {...formik.getFieldProps("email")}
                      />
                      <CInvalidFeedback>{formik.errors.email}</CInvalidFeedback>
                    </CInputGroup>
                    <CRow>
                      <CCol xs="12">
                        {!isRequestSent ? (
                          <CButton type="submit" color="primary" block>
                            {formik.isSubmitting || isLoading ? (
                              <CSpinner color="white" size="sm" />
                            ) : (
                              "Küldés"
                            )}
                          </CButton>
                        ) : (
                          <CAlert color="success" className="mt-3">
                            Jelszó emlékeztető elküldve, átirányítás
                            folyamatban.
                          </CAlert>
                        )}
                      </CCol>
                      <CCol xs="12" className="text-right">
                        <Link to="/sing-in">
                          <CButton color="link">Bejelentkezés oldal</CButton>
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default PasswordResetInitiator;
